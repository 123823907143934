@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
    font-size: 17px;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }


@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }


/* === Core Styles === */
/* Reset Styles */
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden; }

.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none; 
  box-shadow: none;
}

.form-control {
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }
  
a,
div, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5,
p,
span {
  text-shadow: none; }

[type=button]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=file] > input[type=button]::-moz-focus-inner,
select::-moz-focus-inner {
  outline: 0; }

input,
.form-control:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  outline-width: 0;
  outline-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline-style: none; }

textarea {
  resize: none;
  overflow-x: hidden; }

.btn,
.btn-group.open .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
  outline: 0;
  outline-offset: 0; }

.bootstrap-select .dropdown-toggle:focus {
  outline: 0;
  outline-offset: 0; }

.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
  outline: 0; }

a:focus,
input:focus {
  border-color: transparent;
  outline: none; }

/* Fonts */
@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Ubuntu/Ubuntu-Light.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Ubuntu/Ubuntu-Light.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Light.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Light.ttf") format("truetype"); }

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Ubuntu/Ubuntu-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Ubuntu/Ubuntu-Regular.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Regular.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Ubuntu/Ubuntu-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Ubuntu/Ubuntu-Medium.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Medium.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../fonts/Ubuntu/Ubuntu-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Ubuntu/Ubuntu-Bold.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Bold.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Bold.ttf") format("truetype"); }

/* Typography */
body {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: initial;
  line-height: normal;
  -webkit-font-smoothing: antialiased; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Poppins", sans-serif; }

p {
  font-size: 0.875rem; }

h1, .h1,
.h1 {
  font-size: 2.19rem; }

h2, .h2,
.h2 {
  font-size: 1.88rem; }

h3, .h3,
.h3 {
  font-size: 1.56rem; }

h4, .h4,
.h4 {
  font-size: 1.13rem; }

h5, .h5,
.h5 {
  font-size: 1rem; }

h6, .h6,
.h6 {
  font-size: .9375rem; }

p {
  font-size: .9375rem;
  line-height: 1.5; }

/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0 !important; }

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto; }

.container-scroller {
  overflow: hidden; }

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px; }

code {
  padding: 5px;
  color: #fe7c96;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  border-radius: 4px; }

.page-header {
  margin: 0 0 1rem 0; }
  .page-header .breadcrumb {
    border: 0;
    margin-bottom: 0; }

.page-title {
  color: #343a40;
  font-size: 1.125rem;
  margin-bottom: 0; }
  .page-title .page-title-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 4px;
    text-align: center;;
}
    .page-title .page-title-icon i {
      font-size: .9375rem;
      line-height: 2rem;
		color:  #00a79d;
}

/* Footer */
.footer {
  background: #f2edf3;
  color: color(dark);
  border-top: 1px solid #e7dee9;
  padding: 30px 1rem;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  font-size: calc(0.875rem - 0.05rem);
  font-family: "Poppins", sans-serif; }
  .footer a {
    color: #1bcfb4;
    font-size: inherit; }
  @media (max-width: 991px) {
    .footer {
      margin-left: 0;
      width: 100%; } }

/*-------------------------------------------------------------------*/
/* === Components === */
/* Cards */
.card {
  border: 0;
  background: #fff; }
  .card .card-body {
    padding: 1rem 2.5rem; }
    .card .card-body + .card-body {
      padding-top: 1rem; }
  .card .card-title {
    color: #343a40;
    margin-bottom: 0.75rem;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem; }
  .card .card-subtitle {
    font-family: "Poppins", sans-serif;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .card .card-description {
    color: #76838f;
    margin-bottom: 1.5rem;
    font-family: "Poppins", sans-serif; }
  .card.card-outline-success {
    border: 1px solid theme-color("success"); }
  .card.card-outline-primary {
    border: 1px solid theme-color("primary"); }
  .card.card-outline-warning {
    border: 1px solid theme-color("warning"); }
  .card.card-outline-danger {
    border: 1px solid theme-color("danger"); }
  .card.card-rounded {
    border-radius: 5px; }
  .card.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2; }
  .card.card-circle-progress {
    color: #ffffff;
    text-align: center; }
  .card.card-img-holder {
    position: relative; }
    .card.card-img-holder .card-img-absolute {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%; }
  .card.bg-blue-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#065efd), color-stop(#3169fd), to(#6f79fc));
    background: linear-gradient(to right, #065efd, #3169fd, #6f79fc);
    color: #fff; }
  .card.bg-orange-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#ff7f2e), to(#fe7452));
    background: linear-gradient(to right, #ff7f2e, #fe7452);
    color: #fff; }
  .card.bg-green-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#24e8a6), to(#09cdd1));
    background: linear-gradient(to right, #24e8a6, #09cdd1);
    color: #fff; }
  .card.card-no-shadow {
    -webkit-box-shadow: none;
    box-shadow: none; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%;
      margin-bottom: 0.75rem; } }

.card-inverse-primary {
  background: rgba(182, 109, 255, 0.2);
  border: 1px solid theme-color-level(#b66dff, 1);
  color: theme-color-level(#b66dff, 3); }

.card-inverse-secondary {
  background: rgba(195, 189, 189, 0.2);
  border: 1px solid theme-color-level(#c3bdbd, 1);
  color: theme-color-level(#c3bdbd, 3); }

.card-inverse-success {
  background: rgba(27, 207, 180, 0.2);
  border: 1px solid theme-color-level(#1bcfb4, 1);
  color: theme-color-level(#1bcfb4, 3); }

.card-inverse-info {
  background: rgba(25, 138, 227, 0.2);
  border: 1px solid theme-color-level(#198ae3, 1);
  color: theme-color-level(#198ae3, 3); }

.card-inverse-warning {
  background: rgba(254, 215, 19, 0.2);
  border: 1px solid theme-color-level(#fed713, 1);
  color: theme-color-level(#fed713, 3); }

.card-inverse-danger {
  background: rgba(254, 124, 150, 0.2);
  border: 1px solid theme-color-level(#fe7c96, 1);
  color: theme-color-level(#fe7c96, 3); }

.card-inverse-light {
  background: rgba(248, 249, 250, 0.2);
  border: 1px solid theme-color-level(#f8f9fa, 1);
  color: theme-color-level(#f8f9fa, 3); }

.card-inverse-dark {
  background: rgba(62, 75, 91, 0.2);
  border: 1px solid theme-color-level(#3e4b5b, 1);
  color: theme-color-level(#3e4b5b, 3); }

/* Checkboxes and Radios */
.form-check {
  position: relative;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: 0; }
  .form-check .form-check-label {
    display: block;
    margin-left: 1.75rem;
    font-size: 0.875rem;
    line-height: 1.5; }
    .rtl .form-check .form-check-label {
      margin-left: 0;
      margin-right: 1.75rem; }
    .form-check .form-check-label input {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 0;
      margin-top: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0); }
      .rtl .form-check .form-check-label input {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:before, .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
      position: absolute;
      top: 0;
      left: 0; }
      .rtl .form-check .form-check-label input[type="checkbox"] + .input-helper:before, .rtl .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:before {
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 2px;
      border: solid #b66dff;
      border-width: 2px;
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms; }
    .form-check .form-check-label input[type="checkbox"] + .input-helper:after {
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms;
      font-family: Material Design Icons;
      opacity: 0;
      filter: alpha(opacity=0);
      -webkit-transform: scale(0);
      transform: scale(0);
      content: '\F12C';
      font-size: .9375rem;
      font-weight: bold;
      color: #ffffff; }
    .form-check .form-check-label input[type="checkbox"]:checked + .input-helper:before {
      background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
      background: linear-gradient(to right, #da8cff, #9a55ff);
      border-width: 0; }
    .form-check .form-check-label input[type="checkbox"]:checked + .input-helper:after {
      width: 18px;
      opacity: 1;
      line-height: 18px;
      filter: alpha(opacity=100);
      -webkit-transform: scale(1);
      transform: scale(1); }
    .form-check .form-check-label input[type="checkbox"]:disabled + .input-helper:before {
      border-color: #ebedf2; }
    .form-check .form-check-label input[type="checkbox"]:disabled:checked + .input-helper:after {
      background: #ebedf2;
      color: #ffffff; }
    .form-check .form-check-label input[type="radio"] + .input-helper:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      border: solid #b66dff;
      border-width: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms; }
      .rtl .form-check .form-check-label input[type="radio"] + .input-helper:before {
        left: auto;
        right: 0; }
    .form-check .form-check-label input[type="radio"] + .input-helper:after {
      content: "";
      width: 8px;
      height: 8px;
      background: #ffffff;
      border-radius: 50%;
      top: 6px;
      left: 6px;
      -webkit-transition: all;
      transition: all;
      transition-duration: 0s;
      -webkit-transition-duration: 250ms;
      transition-duration: 250ms;
      opacity: 0;
      filter: alpha(opacity=0);
      -webkit-transform: scale(0);
      transform: scale(0);
      position: absolute; }
      .rtl .form-check .form-check-label input[type="radio"] + .input-helper:after {
        left: auto;
        right: 6px; }
    .form-check .form-check-label input[type="radio"]:checked + .input-helper:before {
      background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
      background: linear-gradient(to right, #da8cff, #9a55ff);
      border-width: 0; }
    .form-check .form-check-label input[type="radio"]:checked + .input-helper:after {
      opacity: 1;
      line-height: 1.5;
      filter: alpha(opacity=100);
      -webkit-transform: scale(1);
      transform: scale(1); }
    .form-check .form-check-label input[type="radio"]:disabled + .input-helper:before {
      border-color: #ebedf2; }
    .form-check .form-check-label input[type="radio"]:disabled:checked + .input-helper:before {
      background: #ebedf2; }
    .form-check .form-check-label input[type="radio"]:disabled:checked + .input-helper:after {
      background: #ffffff; }

.form-check-primary.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-primary.form-check label input[type="radio"] + .input-helper:before {
  border-color: theme-color(primary); }

.form-check-primary.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-primary.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
  background: linear-gradient(to right, #da8cff, #9a55ff); }

.form-check-secondary.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-secondary.form-check label input[type="radio"] + .input-helper:before {
  border-color: theme-color(secondary); }

.form-check-secondary.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-secondary.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#e7ebf0), to(#868e96));
  background: linear-gradient(to right, #e7ebf0, #868e96); }

.form-check-success.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-success.form-check label input[type="radio"] + .input-helper:before {
  border-color: theme-color(success); }

.form-check-success.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-success.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae));
  background: linear-gradient(to right, #84d9d2, #07cdae); }

.form-check-info.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-info.form-check label input[type="radio"] + .input-helper:before {
  border-color: theme-color(info); }

.form-check-info.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-info.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf));
  background: linear-gradient(to right, #90caf9, #047edf 99%); }

.form-check-warning.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-warning.form-check label input[type="radio"] + .input-helper:before {
  border-color: theme-color(warning); }

.form-check-warning.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-warning.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
  background: linear-gradient(to right, #f6e384, #ffd500); }

.form-check-danger.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-danger.form-check label input[type="radio"] + .input-helper:before {
  border-color: theme-color(danger); }

.form-check-danger.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-danger.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
  background: linear-gradient(to right, #ffbf96, #fe7096); }

.form-check-light.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-light.form-check label input[type="radio"] + .input-helper:before {
  border-color: theme-color(light); }

.form-check-light.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-light.form-check label input[type="radio"]:checked + .input-helper:before {
  background: -webkit-gradient(linear, left top, left bottom, from(#f4f4f4), to(#e4e4e9));
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9); }

.form-check-dark.form-check label input[type="checkbox"] + .input-helper:before,
.form-check-dark.form-check label input[type="radio"] + .input-helper:before {
  border-color: theme-color(dark); }

.form-check-dark.form-check label input[type="checkbox"]:checked + .input-helper:before,
.form-check-dark.form-check label input[type="radio"]:checked + .input-helper:before {
  background: linear-gradient(89deg, #5e7188, #3e4b5b); }

/* Dropdowns */
.dropdown .dropdown-toggle:after {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  font: normal normal normal 24px/1 "Material Design Icons";
  content: "\f140";
  width: auto;
  height: auto;
  vertical-align: baseline;
  font-size: .75rem; }

.dropdown .dropdown-menu {
  margin-top: .75rem;
  font-size: 0.875rem;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35); }
  .dropdown .dropdown-menu .dropdown-item {
    font-size: 1rem;
    padding: .25rem 1.5rem; }
    .dropdown .dropdown-menu .dropdown-item:active {
      background: initial; }
    .dropdown .dropdown-menu .dropdown-item:hover {
      background-color: #008f0c;
      color: #ffffff; }

/* Forms */
.form-group {
  margin-bottom: 1rem; }

.input-group-append,
.input-group-prepend {
  color: #c9c8c8;
  width: auto;
  border: none; }
  .input-group-append .input-group-text,
  .input-group-prepend .input-group-text {
    border-color: #ebedf2;
    padding: 0.5 0.75rem;
    color: #c9c8c8; }

.form-control {
  border: 1px solid #ebedf2;
  font-family: "Poppins", sans-serif;
  font-size: 0.8125rem; }
  .form-control:focus {
    background-color: #ffffff;
    color: #000000;
    outline: none; }

select.form-control {
  padding: .4375rem .75rem;
  border: 0;
  outline: 1px solid #ebedf2;
  color: #c9c8c8; }
  select.form-control:focus {
    outline: 1px solid #ebedf2; }
  select.form-control.border-primary {
    outline: 1px solid #b66dff; }
    select.form-control.border-primary:focus {
      outline: 1px solid #b66dff; }
  select.form-control.border-secondary, select.form-control.loader-demo-box {
    outline: 1px solid #c3bdbd; }
    select.form-control.border-secondary:focus, select.form-control.loader-demo-box:focus {
      outline: 1px solid #c3bdbd; }
  select.form-control.border-success {
    outline: 1px solid #1bcfb4; }
    select.form-control.border-success:focus {
      outline: 1px solid #1bcfb4; }
  select.form-control.border-info {
    outline: 1px solid #198ae3; }
    select.form-control.border-info:focus {
      outline: 1px solid #198ae3; }
  select.form-control.border-warning {
    outline: 1px solid #fed713; }
    select.form-control.border-warning:focus {
      outline: 1px solid #fed713; }
  select.form-control.border-danger {
    outline: 1px solid #fe7c96; }
    select.form-control.border-danger:focus {
      outline: 1px solid #fe7c96; }
  select.form-control.border-light {
    outline: 1px solid #f8f9fa; }
    select.form-control.border-light:focus {
      outline: 1px solid #f8f9fa; }
  select.form-control.border-dark {
    outline: 1px solid #3e4b5b; }
    select.form-control.border-dark:focus {
      outline: 1px solid #3e4b5b; }

.form-group label {
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: top;
  margin-bottom: .5rem; }

.form-group.has-danger .form-control {
  border-color: #fe7c96; }

.form-group .file-upload-default {
  visibility: hidden;
  position: absolute; }

.form-group .file-upload-info {
  background: transparent; }

/* Icons */
.icons-list {
  border-left: 1px solid #ebedf2;
  border-top: 1px solid #ebedf2; }
  .icons-list > div {
    border-bottom: 1px solid #ebedf2;
    border-right: 1px solid #ebedf2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem; }
    .icons-list > div i {
      display: inline-block;
      font-size: 20px;
      width: 40px;
      text-align: left;
      color: #b66dff; }

/* Tables */
.table {
  margin-bottom: 0; }
  .table thead th {
    border-top: 0;
    border-bottom-width: 1px;
    font-family: "Poppins", sans-serif;
    font-weight: initial; }
    .table thead th i {
      margin-left: 0.325rem; }
  .table th,
  .table td {
    vertical-align: middle;
    font-size: 0.875rem;
    line-height: 1;
    white-space: nowrap;
    padding: 0.9375rem; }
    .table th img,
    .table td img {
      width: 36px;
      height: 36px;
      border-radius: 100%; }
    .table th .badge,
    .table td .badge {
      margin-bottom: 0; }
  .table.table-borderless {
    border: none; }
    .table.table-borderless tr,
    .table.table-borderless td,
    .table.table-borderless th {
      border: none; }

.table > :not(:last-child) > :last-child > *, .jsgrid .jsgrid-table > :not(:last-child) > :last-child > * {
  border-bottom-color: #64867bc6; }

.table > :not(:first-child), .jsgrid .jsgrid-table > :not(:first-child) {
  border-top: none; }


/*-------------------------------------------------------------------*/
/* === Landing screens === */
/* --------- Auth--- */
.auth .auth-form-light {
  background: #ffffff; }
  .auth .auth-form-light select {
    color: #c9c8c8; }
  .auth .auth-form-light .input-group .form-control:focus, .auth .auth-form-light .input-group .form-control:active {
    border-color: #ebedf2; }

.auth .brand-logo {
  margin-bottom: 2rem; }
  .auth .brand-logo img {
    width: 80px;
  }

.auth form .form-group {
  margin-bottom: 1.5rem; }
  .auth form .form-group label {
    font-size: .8125rem; }
  .auth form .form-group .form-control {
    background: transparent;
    border-radius: 0;
    font-size: .9375rem; }

.auth form .auth-form-btn {
  height: 50px;
  line-height: 1.5; }

.auth form .auth-link {
  font-size: 0.875rem; }
  .auth form .auth-link:hover {
    color: initial; }

/* Navbar */
.navbar {
  font-family: "Poppins", sans-serif;
  background: #fff;
  transition: background 0.25s ease;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease; }
  .navbar .navbar-brand-wrapper {
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
    -moz-transition: width 0.25s ease, background 0.25s ease;
    -ms-transition: width 0.25s ease, background 0.25s ease;
    background: #fff; }
    @media (max-width: 991px) {
      .navbar .navbar-brand-wrapper {
        width: 55px; } }
    .navbar .navbar-brand-wrapper .navbar-brand {
      color: #27367f;
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      padding: .25rem 0;
      width: 100%; }
      .navbar .navbar-brand-wrapper .navbar-brand:active, .navbar .navbar-brand-wrapper .navbar-brand:focus, .navbar .navbar-brand-wrapper .navbar-brand:hover {
        color: #1b2658; }
      .navbar .navbar-brand-wrapper .navbar-brand img {
        width: calc(260px - 120px);
        max-width: 100%;
        height: 28px;
        margin: auto;
        vertical-align: middle; }
      .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
        display: none; }
        .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
          width: calc(70px - 50px);
          max-width: 100%;
          height: 28px;
          margin: auto; }
  .navbar .navbar-menu-wrapper {
    transition: width 0.25s ease;
    -webkit-transition: width 0.25s ease;
    -moz-transition: width 0.25s ease;
    -ms-transition: width 0.25s ease;
    color: #9c9fa6;
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100% - 260px);
    height: 70px; }
    @media (max-width: 991px) {
      .navbar .navbar-menu-wrapper {
        width: auto;
        padding-left: 15px;
        padding-right: 15px; } }
    .navbar .navbar-menu-wrapper .navbar-toggler {
      border: 0;
      color: inherit;
      height: 5rem;
      border-radius: 0px;
      padding-left: 5px;
      padding-right: 20px; }
      .navbar .navbar-menu-wrapper .navbar-toggler:focus {
        -webkit-box-shadow: none;
        box-shadow: none; }
      .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
        font-size: 1.5rem; }
        @media (max-width: 991px) {
          .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
            display: none; } }
      @media (max-width: 991px) {
        .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
          padding-left: 15px;
          padding-right: 11px;
          border-right: none; } }
    .navbar .navbar-menu-wrapper .search-field .input-group input {
      font-size: 0.875rem;
      padding: .5rem; }
      .navbar .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
        font-size: 0.875rem;
        color: #9c9fa6;
        font-family: "Poppins", sans-serif; }
      .navbar .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
        font-size: 0.875rem;
        color: #9c9fa6;
        font-family: "Poppins", sans-serif; }
      .navbar .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
        font-size: 0.875rem;
        color: #9c9fa6;
        font-family: "Poppins", sans-serif; }
      .navbar .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
        font-size: 0.875rem;
        color: #9c9fa6;
        font-family: "Poppins", sans-serif; }
    .navbar .navbar-menu-wrapper .search-field .input-group i {
      font-size: 17px;
      margin-right: 0;
      color: #9c9fa6; }
    .navbar .navbar-menu-wrapper .search-field .input-group .input-group-text {
      background: transparent; }
    .navbar .navbar-menu-wrapper .count-indicator {
      position: relative; }
      .navbar .navbar-menu-wrapper .count-indicator .count-symbol,
      .navbar .navbar-menu-wrapper .count-indicator .count-number {
        position: absolute;
        border-radius: 100%;
        border: 2px solid #ffffff; }
      .navbar .navbar-menu-wrapper .count-indicator .count-symbol {
        top: 17px;
        right: -3px;
        width: 10px;
        height: 10px; }
      .navbar .navbar-menu-wrapper .count-indicator .count-number {
        min-width: 14px;
        height: 1rem;
        font-size: .5rem;
        color: #ffffff;
        bottom: 16px;
        right: -5px;
        line-height: 1;
        text-align: center; }
      .navbar .navbar-menu-wrapper .count-indicator:after {
        display: none; }
    .navbar .navbar-menu-wrapper .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
        color: inherit;
        font-size: 0.875rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        height: 2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        @media (max-width: 767px) {
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
            margin-left: .8rem;
            margin-right: .8rem; } }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link i {
          font-size: 1.25rem; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-toggle:after {
        color: #b66dff;
        font-size: 1rem; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
        border: none;
        border-radius: 5px;
        -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2); }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown {
          position: absolute;
          font-size: 0.9rem;
          margin-top: 0;
          padding: 0; }
          .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown {
            right: auto;
            left: 0; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
            margin-bottom: 0;
            padding: 1rem px 1rem;
            cursor: pointer; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item i {
              font-size: 17px; }
            .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item .ellipsis {
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis; }
            .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item i {
              margin-left: 10px; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-divider {
            margin: 0; }
      @media (max-width: 991px) {
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
          position: static; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
            left: 20px;
            right: 20px;
            top: 70px;
            width: calc(100% - 40px); } }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
        padding-left: 20px;
        padding-right: 5px; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
          margin-right: 0;
          margin-left: 0; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img {
        position: relative;
        width: 32px;
        height: 32px;
}
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img img {
          width: 100%;
			height: 32px;
          border-radius: 100%;
			object-fit: cover;
			object-position: top;
}
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status {
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          border: 2px solid #ffffff;
          top: -3px;
          right: -5px; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status.online {
            background: #1bcfb4; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status.offline {
            background: #fe7c96; }
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-img .availability-status.busy {
            background: #fed713; }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
        margin-left: 1.25rem; }
        .rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
          margin-left: 0;
          margin-right: 1.25rem; }
        .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text p {
          line-height: 1; }
        @media (max-width: 767px) {
          .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-text {
            display: none; } }
      .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link.dropdown-toggle:after {
        line-height: 2; }
      @media (min-width: 992px) {
        .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
          margin-left: auto; }
          .rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
            margin-left: 0;
            margin-right: auto; } }

@media (max-width: 991px) {
  .navbar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .navbar .navbar-brand-wrapper {
      width: 75px; }
      .navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
        display: none; }
      .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
        display: inline-block; }
  .navbar-collapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.5rem; } }

@media (max-width: 480px) {
  .navbar .navbar-brand-wrapper {
    width: 55px; }
    .navbar .navbar-brand-wrapper .brand-logo-mini {
      padding-top: 0px; } }

/* Navbar color variations */
.navbar.navbar-primary .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#da8cff), to(#9a55ff));
  background: linear-gradient(to right, #da8cff, #9a55ff);
  color: #ffffff; }
  .navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-primary .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-secondary .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#e7ebf0), to(#868e96));
  background: linear-gradient(to right, #e7ebf0, #868e96);
  color: #ffffff; }
  .navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-secondary .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-success .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae));
  background: linear-gradient(to right, #84d9d2, #07cdae);
  color: #ffffff; }
  .navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-success .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-success .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-info .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf));
  background: linear-gradient(to right, #90caf9, #047edf 99%);
  color: #ffffff; }
  .navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-info .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-info .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-warning .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#f6e384), to(#ffd500));
  background: linear-gradient(to right, #f6e384, #ffd500);
  color: #ffffff; }
  .navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-warning .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-danger .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096));
  background: linear-gradient(to right, #ffbf96, #fe7096);
  color: #ffffff; }
  .navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-danger .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-light .navbar-menu-wrapper {
  background: -webkit-gradient(linear, left top, left bottom, from(#f4f4f4), to(#e4e4e9));
  background: linear-gradient(to bottom, #f4f4f4, #e4e4e9);
  color: #ffffff; }
  .navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-light .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-light .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

.navbar.navbar-dark .navbar-menu-wrapper {
  background: linear-gradient(89deg, #5e7188, #3e4b5b);
  color: #ffffff; }
  .navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input:-ms-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input:-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input::-moz-placeholder {
    color: #ffffff; }
  .navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group input::-webkit-input-placeholder {
    color: #ffffff; }
  .navbar.navbar-dark .navbar-menu-wrapper .search-field .input-group i {
    color: #ffffff; }

/* Sidebar */
.sidebar {
  min-height: calc(100vh - 70px);
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  padding: 0;
  width: 260px;
  z-index: 11;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease; }
  .sidebar .nav {
    overflow: hidden;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 60px; }
    .sidebar .nav .nav-item {
      padding: 0 2.25rem;
      -webkit-transition-duration: 0.25s;
      transition-duration: 0.25s;
      transition-property: background;
      -webkit-transition-property: background; }
      .sidebar .nav .nav-item .collapse {
        z-index: 999; }
      .sidebar .nav .nav-item .nav-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        white-space: nowrap;
        padding: 1.125rem 0 1.125rem 0;
        color: #3e4b5b;
        -webkit-transition-duration: 0.45s;
        transition-duration: 0.45s;
        transition-property: color;
        -webkit-transition-property: color; }
        .sidebar .nav .nav-item .nav-link i {
          color: inherit; }
          .sidebar .nav .nav-item .nav-link i.menu-icon {
            font-size: 1.125rem;
            line-height: 1;
            margin-left: auto;
            color:  #00a79d; }
            .rtl .sidebar .nav .nav-item .nav-link i.menu-icon {
              margin-left: 0;
              margin-right: auto; }
            .sidebar .nav .nav-item .nav-link i.menu-icon:before {
              vertical-align: middle; }
          .sidebar .nav .nav-item .nav-link i.menu-arrow {
            font: normal normal normal 24px/1 "Material Design Icons";
            line-height: 1;
            font-size: 1.125rem;
            margin-left: auto;
            color: #9e9da0; }
            .rtl .sidebar .nav .nav-item .nav-link i.menu-arrow {
              margin-left: 0;
              margin-right: auto; }
            .sidebar .nav .nav-item .nav-link i.menu-arrow:before {
              content: "\f141";
              font-size: inherit;
              color: inherit; }
            .sidebar .nav .nav-item .nav-link i.menu-arrow + .menu-icon {
              margin-left: .25rem; }
              .rtl .sidebar .nav .nav-item .nav-link i.menu-arrow + .menu-icon {
                margin-left: 0;
                margin-right: .25rem; }
        .sidebar .nav .nav-item .nav-link .menu-title {
          color: inherit;
          display: inline-block;
          font-size: 0.875rem;
          line-height: 1;
          vertical-align: middle; }
        .sidebar .nav .nav-item .nav-link .badge {
          margin-right: auto;
          margin-left: 1rem; }
        .sidebar .nav .nav-item .nav-link[aria-expanded="true"] .menu-arrow:before {
          content: "\f140"; }
      .sidebar .nav .nav-item.active {
        background: #ffffff; }
        .sidebar .nav .nav-item.active > .nav-link .menu-title {
          color: #b66dff;
          font-family: "Poppins", sans-serif; }
        .sidebar .nav .nav-item.active > .nav-link i {
          color: #b66dff; }
      .sidebar .nav .nav-item:hover {
        background: #fcfcfc; }
      .sidebar .nav .nav-item.nav-profile .nav-link {
        height: auto;
        line-height: 1;
        border-top: 0;
        padding: 1.25rem 0; }
        .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image {
          width: 44px;
          height: 44px; }
          .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-image img {
            width: 44px;
            height: 44px;
            border-radius: 100%;
			  object-fit: cover;
			  object-position: top;
}
        .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-text {
          margin-left: 1rem; }
          .rtl .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-text {
            margin-left: auto;
            margin-right: 1rem; }
        .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-badge {
          font-size: 1.125rem;
          margin-left: auto; }
          .rtl .sidebar .nav .nav-item.nav-profile .nav-link .nav-profile-badge {
            margin-left: 0;
            margin-right: auto; }
      .sidebar .nav .nav-item.sidebar-actions {
        margin-top: 1rem; }
        .sidebar .nav .nav-item.sidebar-actions .nav-link {
          border-top: 0;
          display: block;
          height: auto; }
        .sidebar .nav .nav-item.sidebar-actions:hover {
          background: initial; }
          .sidebar .nav .nav-item.sidebar-actions:hover .nav-link {
            color: initial; }
    .sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-category):not(.nav-profile) > .nav-link {
      color: #29323d; }
    .sidebar .nav.sub-menu {
      margin-bottom: 20px;
      margin-top: 0;
      list-style: none; }
      .sidebar .nav.sub-menu .nav-item {
        padding: 0; }
        .sidebar .nav.sub-menu .nav-item .nav-link {
          color: #888;
          padding: 0.75rem 2rem 0.75rem 2rem;
          position: relative;
          font-size: 0.8125rem;
          line-height: 1;
          height: auto;
          border-top: 0; }
          .sidebar .nav.sub-menu .nav-item .nav-link:before {
            content: "\F054";
            font-family: "Material Design Icons";
            display: block;
            position: absolute;
            left: 0px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            color: #a2a2a2;
            font-size: .75rem; }
            .rtl .sidebar .nav.sub-menu .nav-item .nav-link:before {
              left: auto;
              right: 0; }
          .sidebar .nav.sub-menu .nav-item .nav-link.active {
            color: #b66dff;
            background: transparent; }
          .sidebar .nav.sub-menu .nav-item .nav-link:hover {
            color: #555555; }
        .sidebar .nav.sub-menu .nav-item:hover {
          background: transparent; }

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - 70px);
    top: 70px;
    bottom: 0;
    overflow: auto;
    right: -260px;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out; }
    .sidebar-offcanvas.active {
      right: 0; } }

/* Layouts */
.navbar.fixed-top + .page-body-wrapper {
  padding-top: 4.5rem; }

@media (min-width: 992px) {
  .sidebar-icon-only .navbar .navbar-brand-wrapper {
    width: 70px; }
    .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo {
      display: none; }
    .sidebar-icon-only .navbar .navbar-brand-wrapper .brand-logo-mini {
      display: inline-block; }
  .sidebar-icon-only .navbar .navbar-menu-wrapper {
    width: calc(100% - 70px); }
  .sidebar-icon-only .sidebar {
    width: 70px; }
    .sidebar-icon-only .sidebar .nav {
      overflow: visible; }
      .sidebar-icon-only .sidebar .nav .nav-item {
        position: relative;
        padding: 0; }
        .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
          display: block;
          text-align: center; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title,
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge, .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
            display: none; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
            border-radius: 0 5px 5px 0px; }
            .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
              border-radius: 5px 0 0 5px; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
            margin-right: 0;
            margin-left: 0; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
            display: none; }
          .sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
            border-radius: 0 5px 0 0px; }
            .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
              border-radius: 5px 0 0 0; }
        .sidebar-icon-only .sidebar .nav .nav-item.nav-profile {
          display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.nav-category {
          display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.nav-doc {
          margin: 0; }
          .sidebar-icon-only .sidebar .nav .nav-item.nav-doc i {
            display: block; }
        .sidebar-icon-only .sidebar .nav .nav-item .collapse {
          display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          background: #fcfcfc;
          padding: 0.5rem 1.4rem;
          left: 70px;
          position: absolute;
          text-align: left;
          top: 0;
          bottom: 0;
          width: 190px;
          z-index: 1;
          line-height: 1.8; }
          .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
            left: auto;
            right: 70px;
            text-align: left; }
          .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title:after {
            display: none; }
        .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
        .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
          display: block;
          padding: .5rem 0;
          background: #fcfcfc;
          border-radius: 0 0 5px 0;
          position: absolute;
          left: 70px;
          width: 190px; }
          .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse, .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
            left: auto;
            right: 70px;
            border-radius: 0 0 0 5px; }
      .sidebar-icon-only .sidebar .nav.sub-menu {
        padding: 0 0 0 1.5rem; }
        .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
          text-align: left;
          padding-left: 20px; }
        .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item {
          margin-right: auto;
          margin-left: 0; }
          .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
            text-align: right; }
            .rtl.sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link:before {
              left: 0;
              right: unset; }
    .sidebar-icon-only .sidebar .sidebar-actions {
      display: none; }
  .sidebar-icon-only .main-panel {
    width: calc(100% - 70px); } }

.rtl {
  direction: rtl;
  text-align: right; }
  .rtl .sidebar .nav {
    padding-right: 0; }
  .rtl .product-chart-wrapper::-webkit-scrollbar,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar,
  .rtl .table-responsive::-webkit-scrollbar,
  .rtl ul.chats::-webkit-scrollbar {
    width: 0.5em; }
  .rtl .product-chart-wrapper::-webkit-scrollbar-track,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar-track,
  .rtl .table-responsive::-webkit-scrollbar-track,
  .rtl ul.chats::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .rtl .product-chart-wrapper::-webkit-scrollbar-thumb,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar-thumb,
  .rtl .table-responsive::-webkit-scrollbar-thumb,
  .rtl ul.chats::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey; }

.page-body-wrapper {
  min-height: calc(100vh - 70px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0; }
  .page-body-wrapper.full-page-wrapper {
    width: 100%;
    min-height: 100vh; }

.main-panel {
  -webkit-transition: width 0.25s ease, margin 0.25s ease;
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 260px);
  min-height: calc(100vh - 70px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
	position: absolute;
	right: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  @media (max-width: 991px) {
    .main-panel {
      margin-left: 0;
      width: 100%; } }

.content-wrapper {
	background-color: rgba(31,85,72,0.1);
  padding: 1rem 2.25rem;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.text-small {
  font-size: 0.9rem !important; 
}



.step {
  height: 5px;
  width: 50px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 10%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  background-color: #398374;
	opacity: 1;
}

.step.finish {
  background-color: #398374;
	opacity: 1;
}

.tab {
	display: none;
}

.welcome-logo {
	width: 10em;
}

.wrapper-welcome{
	background-color: rgba(31,85,72,0.1);
}

.goods{
	display: grid;
	grid-template-columns: auto auto auto auto auto auto;
	justify-content: flex-start;
	align-content: flex-start;
}

.loader{
   border: none;
    border-spacing: 2px;
    opacity: 100;
    animation-name: loader;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

@keyframes loader {
    0% {border-top: solid 5px white;}
    25% {border-right: solid 5px white;}
    50% {border-bottom: solid 5px white;}
    75% {border-left: solid 5px white;}
}

.bg-success{
  background: #00a79d !important;
}
.text-success {
  color: #00a79d !important;
}
.btn-success {
  background-color: #00a79d !important;
}

.order-table thead tr th {
  position: sticky;
  top: 0;
  z-index: 10;
}
tr.hoverable:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.loaderUS {
  animation: l1 1s infinite;
}

@keyframes l1 {
  100% {
      opacity: 0%;
      transform: scale(1.5)}
}

img.hoverable:hover {
  transform: scale(1.1);
}

/* Stock list view changes */
.table-container {
  height: 80vh; 
  width: 100%;
}

.table-row {
  display: flex;
  width: 100%;
}

.table-cell {
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.table-cell img {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 8px;
}
